export const CUSTOM_WIDTH_OPTIONS = [
    {
        label: 'Tablet (1000px)',
        value: '1000',
    },
    {
        label: 'Laptop Screen (1400px)',
        value: '1400',
    },
    {
        label: 'External Monitor (1500px)',
        value: '1500',
    },
    {
        label: 'TV Screen (1600px)',
        value: '1600',
    },
];
