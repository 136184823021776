export enum TimeZone {
    'UTC' = 'UTC', // A special value which we use to make skip tz behavior
    // 'Local' = 'Local', // TODO: decide what to do with local option UX
    'Pacific/Pago_Pago' = 'Pacific/Pago_Pago',
    'Pacific/Honolulu' = 'Pacific/Honolulu',
    'America/Anchorage' = 'America/Anchorage',
    'America/Los_Angeles' = 'America/Los_Angeles',
    'America/Denver' = 'America/Denver',
    'America/Chicago' = 'America/Chicago',
    'America/New_York' = 'America/New_York',
    'America/Santo_Domingo' = 'America/Santo_Domingo',
    'America/Buenos_Aires' = 'America/Buenos_Aires',
    'America/Noronha' = 'America/Noronha',
    'Atlantic/Cape_Verde' = 'Atlantic/Cape_Verde',
    'Europe/Paris' = 'Europe/Paris',
    'Europe/Athens' = 'Europe/Athens',
    'Europe/Moscow' = 'Europe/Moscow',
    'Asia/Dubai' = 'Asia/Dubai',
    'Asia/Karachi' = 'Asia/Karachi',
    'Asia/Dhaka' = 'Asia/Dhaka',
    'Asia/Bangkok' = 'Asia/Bangkok',
    'Asia/Shanghai' = 'Asia/Shanghai',
    'Asia/Tokyo' = 'Asia/Tokyo',
    'Australia/Sydney' = 'Australia/Sydney',
    'Pacific/Noumea' = 'Pacific/Noumea',
    'Pacific/Auckland' = 'Pacific/Auckland',
    'Pacific/Apia' = 'Pacific/Apia',
    'Pacific/Kiritimati' = 'Pacific/Kiritimati',
}
